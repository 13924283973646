import DialogBox from '@/component/dialog'
import refund_hange_policy_get from '@/lib/data-service/haolv-default/consumer_t-tp-refund-change-policy_get'
//获取用车时段枚举
import consumer_car_order_getTransportTimeType
    from '@/lib/data-service/haolv-default/consumer_car_order_getTransportTimeType'
//获取跨城限制枚举
import consumer_car_order_getAcrossCityLimitsType
    from '@/lib/data-service/haolv-default/consumer_car_order_getAcrossCityLimitsType'
//获取用车地点枚举
import consumer_car_order_getUseCarLocationType
    from '@/lib/data-service/haolv-default/consumer_car_order_getUseCarLocationType'
//获取用车车型枚举
import consumer_car_order_getCarType from '@/lib/data-service/haolv-default/consumer_car_order_getCarType'
//城市列表
import consumer_car_order_getCitiesForCar from '@/lib/data-service/haolv-default/consumer_car_order_getCitiesForCar'
//添加出差用车差标
import consumer_web_policyConfig_addBusinessCarPolicy
    from '@/lib/data-service/haolv-default/consumer_web_policyConfig_addBusinessCarPolicy'
//添加加班用车差标
import consumer_web_policyConfig_addWorkCarPolicy
    from '@/lib/data-service/haolv-default/consumer_web_policyConfig_addWorkCarPolicy'
//添加接送服务用车差标
import consumer_web_policyConfig_addShuttleServicePolicy
    from '@/lib/data-service/haolv-default/consumer_web_policyConfig_addShuttleServicePolicy'
//添加代叫用车差标
import consumer_web_policyConfig_addGenerationCarPolicy
    from '@/lib/data-service/haolv-default/consumer_web_policyConfig_addGenerationCarPolicy'
//添加定点通勤用车差标
import consumer_web_policyConfig_addFixedCommutingPolicy
    from '@/lib/data-service/haolv-default/consumer_web_policyConfig_addFixedCommutingPolicy'
//添加外勤用车差标
import consumer_web_policyConfig_addFieldCarPolicy
    from '@/lib/data-service/haolv-default/consumer_web_policyConfig_addFieldCarPolicy'

import PositionLevelChoose from '@/component/positionLevelChoose/index.vue'
import EmployeeLevelChoose from '@/component/employeeLevelChoose/index.vue'
import TimePickerNextDay from '../../common/TimePickerNextDay/1.0.1/index.vue'
import GaodeMapDialog from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/map-dialog/1.0.0/index.vue'
import consumer_tPdProductSet from '@/lib/data-service/haolv-default/consumer_tPdProductSet'

export default {
    name: "evectionCar",
    data() {
        return {
            loading: false,
            tags: [],
            formRules: {
                systemName: [
                    {
                        required: true,
                        validator: (rule, value, callback) => {
                            if (!this.formData.systemName) {
                                callback(new Error("请输入制度名称"));
                            }
                            callback();
                        },
                        trigger: 'blur'
                    }
                ],
                vehicleTime: [
                    {
                        required: true,
                        validator: (rule, value, callback) => {
                            if ((this.useDateAll == null || this.useDateAll.length == 0) && this.formData.useDateLimit == 1) {
                                callback(new Error("请选择用车时间--可用日期--限制的日期"));
                            }
                            if (
                                this.formData.transportTime == 2 &&
                                (
                                    this.formData.transportTimeInfo.workingDay == 0 &&
                                    this.formData.transportTimeInfo.supWorkingDay == 0 &&
                                    this.formData.transportTimeInfo.workHolidays == 0
                                )
                            ) {
                                callback(new Error("请选择工作日/补充工作日/周末及法定节假日"));
                            }
                            if (this.formData.transportTimeInfo.supWorkingDay != 0 && this.tags.length == 0) {
                                callback(new Error("请选择要补充的工作日"));
                            }
                            callback();
                        }, trigger: "blur"
                    },
                ],
                useCarLocationType: [{required: true, message: '请选择用车地点', trigger: 'blur'}],
                workLocations: [
                    {
                        required: true,
                        validator: (rule, value, callback) => {
                            if (this.formData.workLocations.length <= 1 && this.policyTypeChoice == 2 && this.formData.useCarLocationType != 1) {
                                callback(new Error("加班用车请选择两个办公地点"));
                            }

                            if (this.formData.workLocations.length == 0 && this.formData.useCarLocationType != 1) {
                                callback(new Error("请选择办公地点"));
                            }
                            if (this.formData.workLocations.length < 2 && this.policyTypeChoice == 5) {
                                callback(new Error("请选择两个或两个以上办公地点"));
                            }
                            callback();
                        },
                        trigger: 'blur'
                    }
                ],
                acrossCityLimits: [
                    {
                        required: true,
                        validator: (rule, value, callback) => {
                            if (this.formData.acrossCityLimits == 3) {
                                this.formData.toFromCities.forEach((item, index) => {
                                    if (item.city1Code == null || item.city2Code == null) {
                                        callback(new Error("请选择可跨城的城市"));
                                    }
                                });
                            }
                            callback();
                        },
                        trigger: 'blur'
                    }
                ],
                usageScenarios: [{required: true, message: '请选择使用场景', trigger: 'blur'}],
                carType: [{required: true, message: '请选择用车车型', trigger: 'blur'}],
                limitOfSingleAmount: [
                    {
                        required: true,
                        validator: (rule, value, callback) => {
                            if (this.formData.limitOfSingleAmount == 1) {
                                if (this.formData.singleAmount == null || this.formData.singleAmount == '') {
                                    callback(new Error("请输入单笔限制金额"));
                                }
                            }
                            callback();
                        },
                        trigger: 'blur'
                    }
                ],
                dailyLimit: [
                    {
                        required: true,
                        validator: (rule, value, callback) => {
                            if (this.formData.dailyLimit == 1) {
                                if (this.formData.dailyLimitAmount == null || this.formData.dailyLimitAmount == '') {
                                    callback(new Error("请输入每日限额金额"));
                                } else {
                                    if (this.formData.monthLimitAmount == null || this.formData.monthLimitAmount == '') {

                                    } else if (Number(this.formData.monthLimitAmount) <= Number(this.formData.dailyLimitAmount)) {
                                        callback(new Error("设置每月限额的金额需不小于每日限额的金额"));
                                    }
                                }
                            }
                            callback();
                        },
                        trigger: 'blur'
                    }
                ],
                monthLimit: [
                    {
                        required: true,
                        validator: (rule, value, callback) => {
                            if (this.formData.monthLimit == 1) {
                                if (this.formData.monthLimitAmount == null || this.formData.monthLimitAmount == '') {
                                    callback(new Error("请输入每月限额金额"));
                                } else {
                                    if (this.formData.dailyLimitAmount == null || this.formData.dailyLimitAmount == '') {

                                    } else if (Number(this.formData.monthLimitAmount) <= Number(this.formData.dailyLimitAmount)) {
                                        callback(new Error("设置每月限额的金额需不小于每日限额的金额"));
                                    }
                                }
                            }
                            callback();
                        },
                        trigger: 'blur'
                    }
                ],
                dailyLimitTimes: [
                    {
                        required: true,
                        validator: (rule, value, callback) => {
                            if (this.formData.dailyLimitTimes === 1) {
                                if (this.formData.dailyTimes === null || this.formData.dailyTimes === '') {
                                    callback(new Error("请输入每日次数"));
                                    return;
                                } else if (Number(this.formData.dailyTimes) === NaN) {
                                    callback(new Error("请输入数字"));
                                    return;
                                } else if (parseInt(this.formData.dailyTimes)!== parseFloat(this.formData.dailyTimes)) {
                                    callback(new Error("请输入整数"));
                                    return;
                                } else if (Number(this.formData.dailyTimes) < 0) {
                                    callback(new Error("设置请每日次数需不小于0"));
                                    return;
                                }
                            }
                            callback();
                        },
                        trigger: 'blur'
                    }
                ]
            },
            positionVoArr: [],
            employeeVoArr: [],
            pickerOptionsAll: {
                disabledDate: time => {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            },
            pickerOptions: {
                disabledDate: time => {
                    if (this.formData.useDateLimit == 0) {
                        return time.getTime() < Date.now() - 8.64e7;
                    } else {
                        let boxEnd = this.formData.useEndDate;
                        let boxStart = `${this.formData.useStartDate.split('-')[0]}-${this.formData.useStartDate.split('-')[1]}-${this.formData.useStartDate.split('-')[2] - 1}`;
                        if (this.limitDateSwitch) {
                            return time.getTime() > new Date(boxEnd) || time.getTime() < new Date(boxStart);
                        }
                    }
                }
            },
            limitDateSwitch: false,
            placeData: {},            //办公地点数据
            useDateAll: [],
            DateMonday: {          //用车时段(自定义)--周一
                customStartTime: '',
                customEndTime: '',
                customWeekTime: '周一',
                nextDay: false,
            },
            DateTuesday: {           //用车时段(自定义)--周二
                customStartTime: '',
                customEndTime: '',
                customWeekTime: '周二',
                nextDay: false,
            },
            DateWednesday: {      //用车时段(自定义)--周三
                customStartTime: '',
                customEndTime: '',
                customWeekTime: '周三',
                nextDay: false,
            },
            DateThursday: {       //用车时段(自定义)--周四
                customStartTime: '',
                customEndTime: '',
                customWeekTime: '周四',
                nextDay: false,
            },
            DateFriday: {         //用车时段(自定义)--周五
                customStartTime: '',
                customEndTime: '',
                customWeekTime: '周五',
                nextDay: false,
            },
            DateSaturday: {         //用车时段(自定义)--周六
                customStartTime: '',
                customEndTime: '',
                customWeekTime: '周六',
                nextDay: false,
            },
            DateSunday: {      //用车时段(自定义)--周日
                customStartTime: '',
                customEndTime: '',
                customWeekTime: '周日',
                nextDay: false,
            },
            supWorkingDaDate: '',  //用车时段(工作日/节假日)--已补充工作日列表
            options: [], //跨城限制--3:不允许跨城，但部分临近城市可以跨城用车--往返临近城市数据集合
            transportTimeOption: [],        //用车时段选项
            acrossCityLimitsOption: [],        //跨城限制选项
            useCarLocationTypeOption: [],        //用车地点选项
            carTypeOption: [],        //用车车型选项
            formData: {
                systemName: '',            //制度名称
                useDateLimit: 0,            //用车时间--可用日期 0：不限制、1：限制
                useEndDate: '',            //用车时间--可用日期(限制)--可用日期结束
                useStartDate: '',          //用车时间--可用日期(限制)--可用日期开始
                transportTime: 1,            //用车时段--1:不限制、2:工作日/节假日、3:自定义
                transportTimeInfo: {          //用车时段(工作日/节假日)
                    supWorkingDates: [],             //用车时段(工作日/节假日)--已补充工作日列表
                    workingDay: 0,                    //用车时段(工作日/节假日)--是否启用工作日时段 0：否、1：是
                    workingTimeInfo: {               //用车时段(工作日/节假日)--是否启用工作日时段(1：是)
                        acrossDay: null,            //用车时段(工作日/节假日)--是否启用工作日时段(1：是)--是否跨日 0：否 1：是
                        endTime: '',                //用车时段(工作日/节假日)--是否启用工作日时段(1：是)--工作日结束时段
                        startTime: '',              //用车时段(工作日/节假日)--是否启用工作日时段(1：是)--工作日开始时段
                    },
                    supWorkingDay: 0,                //用车时段(工作日/节假日)--是否启用补充工作日时段 0：否、1：是
                    supWorkingTimeInfo: {           //用车时段(工作日/节假日)--补充工作日开始时段(1：是)
                        acrossDay: null,            //用车时段(工作日/节假日)--补充工作日开始时段(1：是)--是否跨日 0：否 1：是
                        endTime: '',                //用车时段(工作日/节假日)--补充工作日开始时段(1：是)--工作日结束时段
                        startTime: '',              //用车时段(工作日/节假日)--补充工作日开始时段(1：是)--工作日开始时段
                    },
                    workHolidays: 0,                 //用车时段(工作日/节假日)--是否启用周末及法定节假日时段 0：否、1：是
                    workHolidaysTimeInfo: {             //用车时段(工作日/节假日)--周末及法定节假日结束时段(1：是)
                        acrossDay: null,            //用车时段(工作日/节假日)--周末及法定节假日结束时段(1：是)--是否跨日 0：否 1：是
                        endTime: '',                //用车时段(工作日/节假日)--周末及法定节假日结束时段(1：是)--工作日结束时段
                        startTime: '',              //用车时段(工作日/节假日)--周末及法定节假日结束时段(1：是)--工作日开始时段
                    },
                },
                transportTimeCustom: {       //用车时段(自定义)
                    mondayTimeInfo: {        //用车时段(自定义)--周一时间信息
                        acrossDay: null,        //用车时段(自定义)--周一时间信息--是否跨日 0：否 1：是
                        endTime: '',            //用车时段(自定义)--周一时间信息--工作日结束时段
                        startTime: '',          //用车时段(自定义)--周一时间信息--工作日开始时段
                    },
                    tuesdayTimeInfo: {       //用车时段(自定义)--周二时间信息
                        acrossDay: null,        //用车时段(自定义)--周二时间信息--是否跨日 0：否 1：是
                        endTime: '',            //用车时段(自定义)--周二时间信息--工作日结束时段
                        startTime: '',          //用车时段(自定义)--周二时间信息--工作日开始时段
                    },
                    wednesdayTimeInfo: {     //用车时段(自定义)--周三时间信息
                        acrossDay: null,        //用车时段(自定义)--周三时间信息--是否跨日 0：否 1：是
                        endTime: '',            //用车时段(自定义)--周三时间信息--工作日结束时段
                        startTime: '',          //用车时段(自定义)--周三时间信息--工作日开始时段
                    },
                    thursdayTimeInfo: {      //用车时段(自定义)--周四时间信息
                        acrossDay: null,        //用车时段(自定义)--周四时间信息--是否跨日 0：否 1：是
                        endTime: '',            //用车时段(自定义)--周四时间信息--工作日结束时段
                        startTime: '',          //用车时段(自定义)--周四时间信息--工作日开始时段
                    },
                    fridayTimeInfo: {        //用车时段(自定义)--周五时间信息
                        acrossDay: null,        //用车时段(自定义)--周五时间信息--是否跨日 0：否 1：是
                        endTime: '',            //用车时段(自定义)--周五时间信息--工作日结束时段
                        startTime: '',          //用车时段(自定义)--周五时间信息--工作日开始时段
                    },
                    saturdayTimeInfo: {      //用车时段(自定义)--周六时间信息
                        acrossDay: null,        //用车时段(自定义)--周六时间信息--是否跨日 0：否 1：是
                        endTime: '',            //用车时段(自定义)--周六时间信息--工作日结束时段
                        startTime: '',          //用车时段(自定义)--周六时间信息--工作日开始时段
                    },
                    sundayTimeInfo: {        //用车时段(自定义)--周日时间信息
                        acrossDay: null,        //用车时段(自定义)--周日时间信息--是否跨日 0：否 1：是
                        endTime: '',            //用车时段(自定义)--周日时间信息--工作日结束时段
                        startTime: '',          //用车时段(自定义)--周日时间信息--工作日开始时段
                    },
                },
                useCarLocationType: 1,            //用车地点 1：不限制、2：从公司到家、3：从家到公司
                workLocations: [                      //办公地点
                    // {
                    //   lat: 1,                         //办公地点--纬度
                    //   lng: 1,                         //办公地点--经度
                    //   locationName: '',                         //办公地点--位置名称
                    //   validRange: '',                         //办公地点--范围限制
                    // }
                ],
                acrossCityLimits: 1,            //跨城限制--1:允许跨城、2:不允许跨城、3:不允许跨城，但部分临近城市可以跨城用车
                toFromCities: [                 //跨城限制(3:不允许跨城，但部分临近城市可以跨城用车--往返临近城市)--往返城市
                    {
                        city1Code: null,              //跨城限制(3:不允许跨城，但部分临近城市可以跨城用车--往返临近城市)--往返城市--城市1行政区编码
                        city2Code: null,              //跨城限制(3:不允许跨城，但部分临近城市可以跨城用车--往返临近城市)--往返城市--城市2行政区编码
                    }
                ],
                usageScenarios: [],            //使用场景 1：实时、2：预约 多个的话中间用逗号隔开
                carType: [],            //用车车型--1:经济、2:舒适、3:专车、4:商务、5:豪华
                carReason: false,   // 用车事由是否开启 0：否 1：是
                limitOfSingleAmount: 0,            //单笔金额限制--0:不限、1:限制
                singleAmount: '',            //单笔金额限制(1:限制)--单笔金额限制
                dailyLimit: 0,            //每日限额--0:不限、1:限制
                dailyLimitAmount: '',            //每日限额(1:限制)--每日限额
                monthLimit: 0,            //每日限额--0:不限、1:限制
                monthLimitAmount: '',            //每日限额(1:限制)--每日限额
                dailyLimitTimes: 0,   //每日次数限制 0：不限 1：限制每日次数
                dailyTimes: '',  //每日次数(1:限制)--每日次数
                positionIds: [],            //职位ID
                userIds: [],            //用户ID
            },
            policyId: null, //编辑用户ID
            policyTypeChoice: null,  //新增用车差标选择类型
            policyTypeChoiceText: null,  //新增用车差标选择类型--text

            individualStandard: 0,
            typeRadio: 0,
        }
    },
    components: {
        DialogBox,
        PositionLevelChoose,
        EmployeeLevelChoose,
        GaodeMapDialog,
        TimePickerNextDay,
    },
    created() {
        this._refund_hange_policy_get()
    },
    watch: {
        positionVoArr(newVal) {
            console.log('newVal-positionVoArr', newVal)
        },
        employeeVoArr(newVal) {
            console.log('newVal-employeeVoArr', newVal)
        }
    },
    activated() {
        this.policyTypeChoice = null;
        this.policyTypeChoiceText = null;
        //用车时段选项请求
        consumer_car_order_getTransportTimeType().then(res => {
            this.transportTimeOption = res.datas.result;
        });
        //跨城限制选项请求
        consumer_car_order_getAcrossCityLimitsType().then(res => {
            this.acrossCityLimitsOption = res.datas.result;
        });
        //用车地点选项请求
        consumer_car_order_getUseCarLocationType().then(res => {
            this.useCarLocationTypeOption = res.datas.result;
        });
        //用车车型选项请求
        consumer_car_order_getCarType().then(res => {
            this.carTypeOption = res.datas.result;
        });

        consumer_car_order_getCitiesForCar().then(res => {
            this.options = res.datas.citys;
        });
        consumer_tPdProductSet().then(res => {
            this.individualStandard = res.datas.individualStandard;
        });
        this.typeRadio = 0;
        this.$refs.formData.clearValidate();
    },
    mounted() {
        this.clickButtonReset();
        this.policyId = this.$route.query.id;

        this.$refs.mapDialog.init({
            //center: [116.3972, 39.9096],
            zoom: 16
        });

        this.$refs.aTimePickerNextDay.init();
        this.$refs.bTimePickerNextDay.init();
        this.$refs.cTimePickerNextDay.init();
        this.$refs.MondayTimeInfoNextDay.init();
        this.$refs.TuesdayTimeInfoNextDay.init();
        this.$refs.WednesdayTimeInfoNextDay.init();
        this.$refs.ThursdayTimeInfoNextDay.init();
        this.$refs.FridayTimeInfoNextDay.init();
        this.$refs.saturdayTimeInfoNextDay.init();
        this.$refs.sundayTimeInfoNextDay.init();

        //用车时间--用车时段(工作日/节假日)--工作日--组件禁用
        this.$refs.aTimePickerNextDay.switch_disable_true();
        //用车时间--用车时段(工作日/节假日)--周末及法定节假日--组件禁用
        this.$refs.cTimePickerNextDay.switch_disable_true();
    },
    methods: {
        radioInput(e) {
            // this.$refs.positionChoose.emptyPosition();
            // this.$refs.employeeChoose.emptyPosition();
        },
        changeMonthLimit() {
            this.$refs.formData.validateField(['monthLimit'], formError => {});
        },
        changeDailyLimit() {
            this.$refs.formData.validateField(['dailyLimit'], formError => {});
        },
        changeLimitOfSingleAmount() {
            this.$refs.formData.validateField(['limitOfSingleAmount'], formError => {});
        },
        visibleToFromCities() {
            this.$refs.formData.validateField(['acrossCityLimits'], formError => {});
        },
        changeAcrossCityLimits(data) {
            this.$refs.formData.validateField(['acrossCityLimits'], formError => {});
            this.formData.toFromCities[0].city1Code = null
            this.formData.toFromCities[0].city2Code = null
        },
        //用车时间--用车时段
        changeTransportTime(data) {
            this.$refs.formData.validateField(['vehicleTime'], formError => {});
        },
        //用车时间--用车时段(工作日/节假日)--周末及法定节假日
        changeWorkHolidays(data) {
            if (data) {
                this.$refs.cTimePickerNextDay.switch_disable_false();
            } else {
                this.$refs.cTimePickerNextDay.switch_disable_true();
            }
            this.$refs.formData.validateField(['vehicleTime'], formError => {});
        },
        //用车时间--用车时段(工作日/节假日)--补充工作日
        changeSupWorkingDay() {
            this.$refs.formData.validateField(['vehicleTime'], formError => {});
        },
        //用车时间--用车时段(工作日/节假日)--工作日
        changeWorkingDay(data) {
            if (data) {
                this.$refs.aTimePickerNextDay.switch_disable_false();
            } else {
                this.$refs.aTimePickerNextDay.switch_disable_true();
            }
            this.$refs.formData.validateField(['vehicleTime'], formError => {});
        },
        changeLimitDate() {
            if (this.formData.useDateLimit == 1 && this.formData.useStartDate != '' && this.formData.useEndDate != '') {
                this.limitDateSwitch = true;
            } else {
                this.limitDateSwitch = false;
            }
            this.$refs.formData.validateField(['vehicleTime'], formError => {});
        },
        clickCityDelent(index) {
            if (this.formData.toFromCities.length != 1) {
                this.formData.toFromCities.splice(index, 1);
            } else {
                this.formData.toFromCities[0].city1Code = null
                this.formData.toFromCities[0].city2Code = null
            }
        },
        //删除办公地点
        clickPlaceDelete(data, index) {
            this.formData.workLocations.splice(index, 1)
        },
        //添加办公地点
        clickAddLocation() {
            this.$refs['mapDialog'].show();
        },
        //组件确定按钮返回值
        submit(val) {
            this.placeData = val;
            this.formData.workLocations.push({
                lat: this.placeData.lat,
                lng: this.placeData.lng,
                locationName: this.placeData.name,
                validRange: this.placeData.range,
            });
            // console.log('选中的值', val)
            this.$refs.formData.validateField(['workLocations'], formError => {});
        },
        //打开组件返回值
        changeMapPoint(val) {
            // console.log('选中的值', val)
        },
        //用车时间--可用日期(限制)--日期选择change
        changeuseDateAll(data) {
            //日期分割
            if (data == null) {
                this.formData.useStartDate = '';
                this.formData.useEndDate = '';
            } else {
                this.formData.useStartDate = data[0];
                this.formData.useEndDate = data[1];
            }
            this.changeLimitDate();
            this.tags = [];
        },
        //添加按钮
        clickButtonAdd() {
            this.formData.toFromCities.push({
                city1Code: null,
                city2Code: null
            })
        },
        handleClose(tag) {
            const item = this.tags.indexOf(tag);
            this.tags.splice(item, 1);
            this.$refs.formData.validateField(['vehicleTime'], formError => {});
        },
        changePicker(data) {
            let timeBox = data;
            let box = true;
            this.tags.forEach((item, index) => {
                if (item.time == timeBox) {
                    box = false;
                }
            });

            if (box) {
                this.tags.push({time: timeBox})
            }
            this.formData.transportTimeInfo.supWorkingDates = [];
            this.tags.forEach((item, index) => {
                this.formData.transportTimeInfo.supWorkingDates.push(item.time);
            });
        },
        //点击补充工作日
        clickAddWeekdays() {
            //初始化日期选择组件--清空上次选择的数据
            this.supWorkingDaDate = null;
            //使日期选择组件获取焦点--弹出
            this.$refs.pickerForm.focus();
        },
        //自定义星期时间信息回填
        customWeekDate() {
            let MondayTimeInfoNextDay = this.$refs.MondayTimeInfoNextDay.get_time_date();
            let TuesdayTimeInfoNextDay = this.$refs.TuesdayTimeInfoNextDay.get_time_date();
            let WednesdayTimeInfoNextDay = this.$refs.WednesdayTimeInfoNextDay.get_time_date();
            let ThursdayTimeInfoNextDay = this.$refs.ThursdayTimeInfoNextDay.get_time_date();
            let FridayTimeInfoNextDay = this.$refs.FridayTimeInfoNextDay.get_time_date();
            let SaturdayTimeInfoNextDay = this.$refs.saturdayTimeInfoNextDay.get_time_date();
            let SundayTimeInfoNextDay = this.$refs.sundayTimeInfoNextDay.get_time_date();

            this.formData.transportTimeCustom.mondayTimeInfo = MondayTimeInfoNextDay;
            this.formData.transportTimeCustom.tuesdayTimeInfo = TuesdayTimeInfoNextDay;
            this.formData.transportTimeCustom.wednesdayTimeInfo = WednesdayTimeInfoNextDay;
            this.formData.transportTimeCustom.thursdayTimeInfo = ThursdayTimeInfoNextDay;
            this.formData.transportTimeCustom.fridayTimeInfo = FridayTimeInfoNextDay;
            this.formData.transportTimeCustom.saturdayTimeInfo = SaturdayTimeInfoNextDay;
            this.formData.transportTimeCustom.sundayTimeInfo = SundayTimeInfoNextDay;
        },
        //底部保存按钮
        clickBottomKeep() {
            this.formData.positionIds = [];
            if (this.typeRadio == 0) {
                this.positionVoArr.forEach((item, index) => {
                    if (item.isEcho) {
                        this.formData.positionIds.push(item.id);
                    }
                });
                if (this.formData.positionIds.length == 0) {
                    this.$message({
                        type: "warning",
                        message: '请先选择适用职级'
                    });
                    return
                }
            }

            this.formData.userIds = [];
            if (this.typeRadio == 1) {
                if (this.$refs.employeeChoose.staffIdList.length === 0) {
                    this.$message({
                        type: "warning",
                        message: '请先选择适用员工'
                    });
                    return
                }
                this.$refs.employeeChoose.staffIdList.forEach(value => {
                    this.formData.userIds.push(value)
                });
            }

            this.$refs.formData.validate((valid) => {
                if (valid) {
                    let data = {};

                    if (this.policyTypeChoice == 1) {
                        //添加出差用车差标
                        data = {
                            acrossCityLimits: this.formData.acrossCityLimits,
                            carType: this.formData.carType.join(','),
                            carReason: this.formData.carReason ? 1 : 0,
                            dailyLimit: this.formData.dailyLimit,
                            dailyLimitAmount: this.formData.dailyLimitAmount,
                            monthLimit: this.formData.monthLimit,
                            monthLimitAmount: this.formData.monthLimitAmount,
                            limitOfSingleAmount: this.formData.limitOfSingleAmount,
                            positionIds: this.formData.positionIds,
                            userIds: this.formData.userIds,
                            singleAmount: this.formData.singleAmount,
                            systemName: this.formData.systemName,
                            toFromCities: this.formData.toFromCities,
                            dailyLimitTimes: this.formData.dailyLimitTimes,
                            dailyTimes: this.formData.dailyTimes,
                        };
                        this.loading = true;
                        consumer_web_policyConfig_addBusinessCarPolicy(data)
                            .then(res => {
                                this.$message({
                                    message: '保存成功',
                                    type: 'success'
                                });
                                setTimeout(() => {
                                    this.clickButtonReset();
                                    this.$router.push({
                                        name: 'admin-evection-car-policy',
                                    })
                                }, 800)
                            })
                            .catch(res => {
                                this.$message.error('保存失败');
                            }).finally(()=>{
                            this.loading = false;
                        })
                    } else if (this.policyTypeChoice == 2) {
                        if (this.formData.transportTime == 2) {
                            //次日组件信息回填
                            //启用工作日时段
                            this.formData.workingTimeInfo = this.$refs.aTimePickerNextDay.get_time_date();
                            if (this.formData.transportTimeInfo.workingDay === 1 && this.formData.workingTimeInfo === null) {
                                this.$message.warning('请输入工作天时间');
                                return;
                            }
                            //补充工作日时段
                            this.formData.supWorkingTimeInfo = this.$refs.bTimePickerNextDay.get_time_date();
                            if (this.formData.transportTimeInfo.supWorkingDay === 1 && this.formData.supWorkingTimeInfo === null) {
                                this.$message.warning('请输入补充工作日时间');
                                return;
                            }
                            // 启用周末及法定节假日
                            this.formData.workHolidaysTimeInfo = this.$refs.cTimePickerNextDay.get_time_date();
                            if (this.formData.transportTimeInfo.workHolidays === 1 && this.formData.workHolidaysTimeInfo === null) {
                                this.$message.warning('请输入周末及法定节假日时间');
                                return;
                            }
                        } else if (this.formData.transportTime == 3) {
                            //自定义星期时间信息回填
                            this.customWeekDate();
                        }

                        //添加加班用车差标
                        data = {
                            acrossCityLimits: this.formData.acrossCityLimits,
                            carType: this.formData.carType.join(','),
                            carReason: this.formData.carReason ? 1 : 0,
                            dailyLimit: this.formData.dailyLimit,
                            dailyLimitAmount: Number(this.formData.dailyLimitAmount),
                            monthLimit: this.formData.monthLimit,
                            monthLimitAmount: Number(this.formData.monthLimitAmount),
                            limitOfSingleAmount: this.formData.limitOfSingleAmount,
                            positionIds: this.formData.positionIds,
                            userIds: this.formData.userIds,
                            singleAmount: Number(this.formData.singleAmount),
                            systemName: this.formData.systemName,
                            toFromCities: this.formData.toFromCities,
                            transportTime: this.formData.transportTime,
                            transportTimeCustom: this.formData.transportTimeCustom,
                            transportTimeInfo: {
                                supWorkingDates: this.formData.transportTimeInfo.supWorkingDates,
                                supWorkingDay: this.formData.transportTimeInfo.supWorkingDay ? 1 : 0,
                                supWorkingTimeInfo: this.formData.supWorkingTimeInfo,
                                workHolidays: this.formData.transportTimeInfo.workHolidays ? 1 : 0,
                                workHolidaysTimeInfo: this.formData.workHolidaysTimeInfo,
                                workingDay: this.formData.transportTimeInfo.workingDay ? 1 : 0,
                                workingTimeInfo: this.formData.workingTimeInfo,
                            },
                            usageScenarios: this.formData.usageScenarios.join(','),
                            useCarLocationType: this.formData.useCarLocationType,
                            useDateLimit: this.formData.useDateLimit,
                            useEndDate: this.formData.useEndDate,
                            useStartDate: this.formData.useStartDate,
                            workLocations: this.formData.workLocations,
                            dailyLimitTimes: this.formData.dailyLimitTimes,
                            dailyTimes: this.formData.dailyTimes,
                        };
                        this.loading = true;
                        consumer_web_policyConfig_addWorkCarPolicy(data)
                            .then(res => {
                                this.$message({
                                    message: '保存成功',
                                    type: 'success'
                                });
                                setTimeout(() => {
                                    this.clickButtonReset();
                                    this.$router.push({
                                        name: 'admin-evection-car-policy',
                                    })
                                }, 800)
                            })
                            .catch(res => {
                                this.$message.error('保存失败');
                            }).finally(()=>{
                            this.loading = false;
                        })
                    } else if (this.policyTypeChoice == 3) {
                        //添加接送服务用车差标
                        data = {
                            acrossCityLimits: this.formData.acrossCityLimits,
                            carType: this.formData.carType.join(','),
                            carReason: this.formData.carReason ? 1 : 0,
                            dailyLimit: this.formData.dailyLimit,
                            dailyLimitAmount: Number(this.formData.dailyLimitAmount),
                            monthLimit: this.formData.monthLimit,
                            monthLimitAmount: Number(this.formData.monthLimitAmount),
                            limitOfSingleAmount: this.formData.limitOfSingleAmount,
                            positionIds: this.formData.positionIds,
                            userIds: this.formData.userIds,
                            singleAmount: Number(this.formData.singleAmount),
                            systemName: this.formData.systemName,
                            toFromCities: this.formData.toFromCities,
                            usageScenarios: this.formData.usageScenarios.join(','),
                            dailyLimitTimes: this.formData.dailyLimitTimes,
                            dailyTimes: this.formData.dailyTimes,
                        };
                        this.loading = true;
                        consumer_web_policyConfig_addShuttleServicePolicy(data)
                            .then(res => {
                                this.$message({
                                    message: '保存成功',
                                    type: 'success'
                                });
                                setTimeout(() => {
                                    this.clickButtonReset();
                                    this.$router.push({
                                        name: 'admin-evection-car-policy',
                                    })
                                }, 800)
                            })
                            .catch(res => {
                                this.$message.error('保存失败');
                            }).finally(()=>{
                            this.loading = false;
                        })
                    } else if (this.policyTypeChoice == 4) {
                        data = {
                            acrossCityLimits: this.formData.acrossCityLimits,
                            carType: this.formData.carType.join(','),
                            carReason: this.formData.carReason ? 1 : 0,
                            dailyLimit: this.formData.dailyLimit,
                            dailyLimitAmount: Number(this.formData.dailyLimitAmount),
                            monthLimit: this.formData.monthLimit,
                            monthLimitAmount: Number(this.formData.monthLimitAmount),
                            limitOfSingleAmount: this.formData.limitOfSingleAmount,
                            positionIds: this.formData.positionIds,
                            userIds: this.formData.userIds,
                            singleAmount: Number(this.formData.singleAmount),
                            systemName: this.formData.systemName,
                            toFromCities: this.formData.toFromCities,
                            dailyLimitTimes: this.formData.dailyLimitTimes,
                            dailyTimes: this.formData.dailyTimes,
                        };
                        //添加代叫用车差标
                        this.loading = true;
                        consumer_web_policyConfig_addGenerationCarPolicy(data)
                            .then(res => {
                                this.$message({
                                    message: '保存成功',
                                    type: 'success'
                                });
                                setTimeout(() => {
                                    this.clickButtonReset();
                                    this.$router.push({
                                        name: 'admin-evection-car-policy',
                                    })
                                }, 800)
                            })
                            .catch(res => {
                                this.$message.error('保存失败');
                            }).finally(()=>{
                            this.loading = false;
                        })
                    } else if (this.policyTypeChoice == 5) {
                        if (this.formData.transportTime == 2) {
                            //次日组件信息回填
                            //启用工作日时段
                            this.formData.workingTimeInfo = this.$refs.aTimePickerNextDay.get_time_date();
                            if (this.formData.transportTimeInfo.workingDay === 1 && this.formData.workingTimeInfo === null) {
                                this.$message.warning('请输入工作天时间');
                                return;
                            }
                            //补充工作日时段
                            this.formData.supWorkingTimeInfo = this.$refs.bTimePickerNextDay.get_time_date();
                            if (this.formData.transportTimeInfo.supWorkingDay === 1 && this.formData.supWorkingTimeInfo === null) {
                                this.$message.warning('请输入补充工作日时间');
                                return;
                            }
                            // 启用周末及法定节假日
                            this.formData.workHolidaysTimeInfo = this.$refs.cTimePickerNextDay.get_time_date();
                            if (this.formData.transportTimeInfo.workHolidays === 1 && this.formData.workHolidaysTimeInfo === null) {
                                this.$message.warning('请输入周末及法定节假日时间');
                                return;
                            }
                        } else if (this.formData.transportTime == 3) {
                            //自定义星期时间信息回填
                            this.customWeekDate();
                        }
                        data = {
                            acrossCityLimits: this.formData.acrossCityLimits,
                            carType: this.formData.carType.join(','),
                            carReason: this.formData.carReason ? 1 : 0,
                            dailyLimit: this.formData.dailyLimit,
                            dailyLimitAmount: Number(this.formData.dailyLimitAmount),
                            monthLimit: this.formData.monthLimit,
                            monthLimitAmount: Number(this.formData.monthLimitAmount),
                            limitOfSingleAmount: this.formData.limitOfSingleAmount,
                            positionIds: this.formData.positionIds,
                            userIds: this.formData.userIds,
                            singleAmount: Number(this.formData.singleAmount),
                            systemName: this.formData.systemName,
                            toFromCities: this.formData.toFromCities,
                            transportTime: this.formData.transportTime,
                            transportTimeCustom: this.formData.transportTimeCustom,
                            transportTimeInfo: {
                                supWorkingDates: this.formData.transportTimeInfo.supWorkingDates,
                                supWorkingDay: this.formData.transportTimeInfo.supWorkingDay ? 1 : 0,
                                supWorkingTimeInfo: this.formData.supWorkingTimeInfo,
                                workHolidays: this.formData.transportTimeInfo.workHolidays ? 1 : 0,
                                workHolidaysTimeInfo: this.formData.workHolidaysTimeInfo,
                                workingDay: this.formData.transportTimeInfo.workingDay ? 1 : 0,
                                workingTimeInfo: this.formData.workingTimeInfo,
                            },
                            useDateLimit: this.formData.useDateLimit,
                            useEndDate: this.formData.useEndDate,
                            useStartDate: this.formData.useStartDate,
                            workLocations: this.formData.workLocations,
                            dailyLimitTimes: this.formData.dailyLimitTimes,
                            dailyTimes: this.formData.dailyTimes,
                        };
                        // 添加定点通勤用车差标
                        this.loading = true;
                        consumer_web_policyConfig_addFixedCommutingPolicy(data)
                            .then(res => {
                                this.$message({
                                    message: '保存成功',
                                    type: 'success'
                                });
                                setTimeout(() => {
                                    this.clickButtonReset();
                                    this.$router.push({
                                        name: 'admin-evection-car-policy',
                                    })
                                }, 800)
                            })
                            .catch(res => {
                                this.$message.error('保存失败');
                            }).finally(()=>{
                            this.loading = false;
                        })
                    } else if (this.policyTypeChoice == 6) {
                        if (this.formData.transportTime == 2) {
                            //次日组件信息回填
                            //启用工作日时段
                            this.formData.workingTimeInfo = this.$refs.aTimePickerNextDay.get_time_date();
                            if (this.formData.transportTimeInfo.workingDay === 1 && this.formData.workingTimeInfo === null) {
                                this.$message.warning('请输入工作天时间');
                                return;
                            }
                            //补充工作日时段
                            this.formData.supWorkingTimeInfo = this.$refs.bTimePickerNextDay.get_time_date();
                            if (this.formData.transportTimeInfo.supWorkingDay === 1 && this.formData.supWorkingTimeInfo === null) {
                                this.$message.warning('请输入补充工作日时间');
                                return;
                            }
                            // 启用周末及法定节假日
                            this.formData.workHolidaysTimeInfo = this.$refs.cTimePickerNextDay.get_time_date();
                            if (this.formData.transportTimeInfo.workHolidays === 1 && this.formData.workHolidaysTimeInfo === null) {
                                this.$message.warning('请输入周末及法定节假日时间');
                                return;
                            }
                        } else if (this.formData.transportTime == 3) {
                            //自定义星期时间信息回填
                            this.customWeekDate();
                        }
                        data = {
                            acrossCityLimits: this.formData.acrossCityLimits,
                            carType: this.formData.carType.join(','),
                            carReason: this.formData.carReason ? 1 : 0,
                            dailyLimit: this.formData.dailyLimit,
                            dailyLimitAmount: Number(this.formData.dailyLimitAmount),
                            monthLimit: this.formData.monthLimit,
                            monthLimitAmount: Number(this.formData.monthLimitAmount),
                            limitOfSingleAmount: this.formData.limitOfSingleAmount,
                            positionIds: this.formData.positionIds,
                            userIds: this.formData.userIds,
                            singleAmount: Number(this.formData.singleAmount),
                            systemName: this.formData.systemName,
                            toFromCities: this.formData.toFromCities,
                            transportTime: this.formData.transportTime,
                            transportTimeCustom: this.formData.transportTimeCustom,
                            transportTimeInfo: {
                                supWorkingDates: this.formData.transportTimeInfo.supWorkingDates,
                                supWorkingDay: this.formData.transportTimeInfo.supWorkingDay ? 1 : 0,
                                supWorkingTimeInfo: this.formData.supWorkingTimeInfo,
                                workHolidays: this.formData.transportTimeInfo.workHolidays ? 1 : 0,
                                workHolidaysTimeInfo: this.formData.workHolidaysTimeInfo,
                                workingDay: this.formData.transportTimeInfo.workingDay ? 1 : 0,
                                workingTimeInfo: this.formData.workingTimeInfo,
                            },
                            useDateLimit: this.formData.useDateLimit,
                            useEndDate: this.formData.useEndDate,
                            useStartDate: this.formData.useStartDate,
                            dailyLimitTimes: this.formData.dailyLimitTimes,
                            dailyTimes: this.formData.dailyTimes,
                        };
                        //添加外勤用车差标
                        this.loading = true;
                        consumer_web_policyConfig_addFieldCarPolicy(data)
                            .then(res => {
                                this.$message({
                                    message: '保存成功',
                                    type: 'success'
                                });
                                setTimeout(() => {
                                    this.clickButtonReset();
                                    this.$router.push({
                                        name: 'admin-evection-car-policy',
                                    })
                                }, 800)
                            })
                            .catch(res => {
                                this.$message.error('保存失败');
                            }).finally(()=>{
                            this.loading = false;
                        })
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        //底部返回按钮
        clickBottomReturn() {
            this.policyTypeChoice = null;
            this.policyTypeChoiceText = null;
            this.$refs.formData.clearValidate();
            this.clickButtonResetTest();
        },
        //选择不同类型的用车--1：出差用车 2：加班用车 3：接送服务 4：代叫用车 5：定点通勤 6：外勤用车
        clickButton(data) {
            //保存选择用车的type和text
            this.policyTypeChoice = data.type;
            this.policyTypeChoiceText = data.typeText;

            //init职级组件
            this.$nextTick(() => {
                this.$refs.positionChoose.init({
                    id: this.$route.query.id,
                    type: this.$route.query.type,
                    businessType: 4,
                });
                this.$refs.employeeChoose.init({
                    id: this.$route.query.id,
                    type: this.$route.query.type,
                    businessType: 1,
                });
            });

        },
        clickButtonResetTest() {
            this.$refs.aTimePickerNextDay.init();
            this.$refs.bTimePickerNextDay.init();
            this.$refs.cTimePickerNextDay.init();
            this.$refs.MondayTimeInfoNextDay.init();
            this.$refs.TuesdayTimeInfoNextDay.init();
            this.$refs.WednesdayTimeInfoNextDay.init();
            this.$refs.ThursdayTimeInfoNextDay.init();
            this.$refs.FridayTimeInfoNextDay.init();
            this.$refs.saturdayTimeInfoNextDay.init();
            this.$refs.sundayTimeInfoNextDay.init();

            //用车时间--用车时段(工作日/节假日)--工作日--组件禁用
            this.$refs.aTimePickerNextDay.switch_disable_true();
            //用车时间--用车时段(工作日/节假日)--周末及法定节假日--组件禁用
            this.$refs.cTimePickerNextDay.switch_disable_true();
            this.useDateAll = [];
            this.formData = {
                systemName: '',            //制度名称
                useDateLimit: 0,            //用车时间--可用日期 0：不限制、1：限制
                useEndDate: '',            //用车时间--可用日期(限制)--可用日期结束
                useStartDate: '',          //用车时间--可用日期(限制)--可用日期开始
                transportTime: 1,            //用车时段--1:不限制、2:工作日/节假日、3:自定义
                transportTimeInfo: {          //用车时段(工作日/节假日)
                    supWorkingDates: [],             //用车时段(工作日/节假日)--已补充工作日列表
                    workingDay: 0,                    //用车时段(工作日/节假日)--是否启用工作日时段 0：否、1：是
                    workingTimeInfo: {               //用车时段(工作日/节假日)--是否启用工作日时段(1：是)
                        acrossDay: null,            //用车时段(工作日/节假日)--是否启用工作日时段(1：是)--是否跨日 0：否 1：是
                        endTime: '',                //用车时段(工作日/节假日)--是否启用工作日时段(1：是)--工作日结束时段
                        startTime: '',              //用车时段(工作日/节假日)--是否启用工作日时段(1：是)--工作日开始时段
                    },
                    supWorkingDay: 0,                //用车时段(工作日/节假日)--是否启用补充工作日时段 0：否、1：是
                    supWorkingTimeInfo: {           //用车时段(工作日/节假日)--补充工作日开始时段(1：是)
                        acrossDay: null,            //用车时段(工作日/节假日)--补充工作日开始时段(1：是)--是否跨日 0：否 1：是
                        endTime: '',                //用车时段(工作日/节假日)--补充工作日开始时段(1：是)--工作日结束时段
                        startTime: '',              //用车时段(工作日/节假日)--补充工作日开始时段(1：是)--工作日开始时段
                    },
                    workHolidays: 0,                 //用车时段(工作日/节假日)--是否启用周末及法定节假日时段 0：否、1：是
                    workHolidaysTimeInfo: {             //用车时段(工作日/节假日)--周末及法定节假日结束时段(1：是)
                        acrossDay: null,            //用车时段(工作日/节假日)--周末及法定节假日结束时段(1：是)--是否跨日 0：否 1：是
                        endTime: '',                //用车时段(工作日/节假日)--周末及法定节假日结束时段(1：是)--工作日结束时段
                        startTime: '',              //用车时段(工作日/节假日)--周末及法定节假日结束时段(1：是)--工作日开始时段
                    },
                },
                transportTimeCustom: {       //用车时段(自定义)
                    mondayTimeInfo: {        //用车时段(自定义)--周一时间信息
                        acrossDay: null,        //用车时段(自定义)--周一时间信息--是否跨日 0：否 1：是
                        endTime: '',            //用车时段(自定义)--周一时间信息--工作日结束时段
                        startTime: '',          //用车时段(自定义)--周一时间信息--工作日开始时段
                    },
                    tuesdayTimeInfo: {       //用车时段(自定义)--周二时间信息
                        acrossDay: null,        //用车时段(自定义)--周二时间信息--是否跨日 0：否 1：是
                        endTime: '',            //用车时段(自定义)--周二时间信息--工作日结束时段
                        startTime: '',          //用车时段(自定义)--周二时间信息--工作日开始时段
                    },
                    wednesdayTimeInfo: {     //用车时段(自定义)--周三时间信息
                        acrossDay: null,        //用车时段(自定义)--周三时间信息--是否跨日 0：否 1：是
                        endTime: '',            //用车时段(自定义)--周三时间信息--工作日结束时段
                        startTime: '',          //用车时段(自定义)--周三时间信息--工作日开始时段
                    },
                    thursdayTimeInfo: {      //用车时段(自定义)--周四时间信息
                        acrossDay: null,        //用车时段(自定义)--周四时间信息--是否跨日 0：否 1：是
                        endTime: '',            //用车时段(自定义)--周四时间信息--工作日结束时段
                        startTime: '',          //用车时段(自定义)--周四时间信息--工作日开始时段
                    },
                    fridayTimeInfo: {        //用车时段(自定义)--周五时间信息
                        acrossDay: null,        //用车时段(自定义)--周五时间信息--是否跨日 0：否 1：是
                        endTime: '',            //用车时段(自定义)--周五时间信息--工作日结束时段
                        startTime: '',          //用车时段(自定义)--周五时间信息--工作日开始时段
                    },
                    saturdayTimeInfo: {      //用车时段(自定义)--周六时间信息
                        acrossDay: null,        //用车时段(自定义)--周六时间信息--是否跨日 0：否 1：是
                        endTime: '',            //用车时段(自定义)--周六时间信息--工作日结束时段
                        startTime: '',          //用车时段(自定义)--周六时间信息--工作日开始时段
                    },
                    sundayTimeInfo: {        //用车时段(自定义)--周日时间信息
                        acrossDay: null,        //用车时段(自定义)--周日时间信息--是否跨日 0：否 1：是
                        endTime: '',            //用车时段(自定义)--周日时间信息--工作日结束时段
                        startTime: '',          //用车时段(自定义)--周日时间信息--工作日开始时段
                    },
                },
                useCarLocationType: 1,            //用车地点 1：不限制、2：从公司到家、3：从家到公司
                workLocations: [                      //办公地点
                    // {
                    //   lat: 1,                         //办公地点--纬度
                    //   lng: 1,                         //办公地点--经度
                    //   locationName: '',                         //办公地点--位置名称
                    //   validRange: '',                         //办公地点--范围限制
                    // }
                ],
                acrossCityLimits: 1,            //跨城限制--1:允许跨城、2:不允许跨城、3:不允许跨城，但部分临近城市可以跨城用车
                toFromCities: [                 //跨城限制(3:不允许跨城，但部分临近城市可以跨城用车--往返临近城市)--往返城市
                    {
                        city1Code: null,              //跨城限制(3:不允许跨城，但部分临近城市可以跨城用车--往返临近城市)--往返城市--城市1行政区编码
                        city2Code: null,              //跨城限制(3:不允许跨城，但部分临近城市可以跨城用车--往返临近城市)--往返城市--城市2行政区编码
                    }
                ],
                usageScenarios: [],            //使用场景 1：实时、2：预约 多个的话中间用逗号隔开
                carType: [],            //用车车型--1:经济、2:舒适、3:专车、4:商务、5:豪华
                carReason: false,   // 用车事由是否开启 0：否 1：是
                limitOfSingleAmount: 0,            //单笔金额限制--0:不限、1:限制
                singleAmount: '',            //单笔金额限制(1:限制)--单笔金额限制
                dailyLimit: 0,            //每日限额--0:不限、1:限制
                dailyLimitAmount: '',            //每日限额(1:限制)--每日限额
                monthLimit: 0,            //每日限额--0:不限、1:限制
                monthLimitAmount: '',            //每日限额(1:限制)--每日限额
                dailyLimitTimes: 0,   //每日次数限制 0：不限 1：限制每日次数
                dailyTimes: '',  //每日次数(1:限制)--每日次数
                positionIds: [],            //职位ID
                userIds: [],            //用户ID
            };
            this.$refs.formData.validateField(['vehicleTime'], formError => {});
        },
        clickButtonReset() {
            this.useDateAll = [];
            this.formData = {
                systemName: '',            //制度名称
                useDateLimit: 0,            //用车时间--可用日期 0：不限制、1：限制
                useEndDate: '',            //用车时间--可用日期(限制)--可用日期结束
                useStartDate: '',          //用车时间--可用日期(限制)--可用日期开始
                transportTime: 1,            //用车时段--1:不限制、2:工作日/节假日、3:自定义
                transportTimeInfo: {          //用车时段(工作日/节假日)
                    supWorkingDates: [],             //用车时段(工作日/节假日)--已补充工作日列表
                    workingDay: 0,                    //用车时段(工作日/节假日)--是否启用工作日时段 0：否、1：是
                    workingTimeInfo: {               //用车时段(工作日/节假日)--是否启用工作日时段(1：是)
                        acrossDay: null,            //用车时段(工作日/节假日)--是否启用工作日时段(1：是)--是否跨日 0：否 1：是
                        endTime: '',                //用车时段(工作日/节假日)--是否启用工作日时段(1：是)--工作日结束时段
                        startTime: '',              //用车时段(工作日/节假日)--是否启用工作日时段(1：是)--工作日开始时段
                    },
                    supWorkingDay: 0,                //用车时段(工作日/节假日)--是否启用补充工作日时段 0：否、1：是
                    supWorkingTimeInfo: {           //用车时段(工作日/节假日)--补充工作日开始时段(1：是)
                        acrossDay: null,            //用车时段(工作日/节假日)--补充工作日开始时段(1：是)--是否跨日 0：否 1：是
                        endTime: '',                //用车时段(工作日/节假日)--补充工作日开始时段(1：是)--工作日结束时段
                        startTime: '',              //用车时段(工作日/节假日)--补充工作日开始时段(1：是)--工作日开始时段
                    },
                    workHolidays: 0,                 //用车时段(工作日/节假日)--是否启用周末及法定节假日时段 0：否、1：是
                    workHolidaysTimeInfo: {             //用车时段(工作日/节假日)--周末及法定节假日结束时段(1：是)
                        acrossDay: null,            //用车时段(工作日/节假日)--周末及法定节假日结束时段(1：是)--是否跨日 0：否 1：是
                        endTime: '',                //用车时段(工作日/节假日)--周末及法定节假日结束时段(1：是)--工作日结束时段
                        startTime: '',              //用车时段(工作日/节假日)--周末及法定节假日结束时段(1：是)--工作日开始时段
                    },
                },
                transportTimeCustom: {       //用车时段(自定义)
                    mondayTimeInfo: {        //用车时段(自定义)--周一时间信息
                        acrossDay: null,        //用车时段(自定义)--周一时间信息--是否跨日 0：否 1：是
                        endTime: '',            //用车时段(自定义)--周一时间信息--工作日结束时段
                        startTime: '',          //用车时段(自定义)--周一时间信息--工作日开始时段
                    },
                    tuesdayTimeInfo: {       //用车时段(自定义)--周二时间信息
                        acrossDay: null,        //用车时段(自定义)--周二时间信息--是否跨日 0：否 1：是
                        endTime: '',            //用车时段(自定义)--周二时间信息--工作日结束时段
                        startTime: '',          //用车时段(自定义)--周二时间信息--工作日开始时段
                    },
                    wednesdayTimeInfo: {     //用车时段(自定义)--周三时间信息
                        acrossDay: null,        //用车时段(自定义)--周三时间信息--是否跨日 0：否 1：是
                        endTime: '',            //用车时段(自定义)--周三时间信息--工作日结束时段
                        startTime: '',          //用车时段(自定义)--周三时间信息--工作日开始时段
                    },
                    thursdayTimeInfo: {      //用车时段(自定义)--周四时间信息
                        acrossDay: null,        //用车时段(自定义)--周四时间信息--是否跨日 0：否 1：是
                        endTime: '',            //用车时段(自定义)--周四时间信息--工作日结束时段
                        startTime: '',          //用车时段(自定义)--周四时间信息--工作日开始时段
                    },
                    fridayTimeInfo: {        //用车时段(自定义)--周五时间信息
                        acrossDay: null,        //用车时段(自定义)--周五时间信息--是否跨日 0：否 1：是
                        endTime: '',            //用车时段(自定义)--周五时间信息--工作日结束时段
                        startTime: '',          //用车时段(自定义)--周五时间信息--工作日开始时段
                    },
                    saturdayTimeInfo: {      //用车时段(自定义)--周六时间信息
                        acrossDay: null,        //用车时段(自定义)--周六时间信息--是否跨日 0：否 1：是
                        endTime: '',            //用车时段(自定义)--周六时间信息--工作日结束时段
                        startTime: '',          //用车时段(自定义)--周六时间信息--工作日开始时段
                    },
                    sundayTimeInfo: {        //用车时段(自定义)--周日时间信息
                        acrossDay: null,        //用车时段(自定义)--周日时间信息--是否跨日 0：否 1：是
                        endTime: '',            //用车时段(自定义)--周日时间信息--工作日结束时段
                        startTime: '',          //用车时段(自定义)--周日时间信息--工作日开始时段
                    },
                },
                useCarLocationType: 1,            //用车地点 1：不限制、2：从公司到家、3：从家到公司
                workLocations: [                      //办公地点
                    // {
                    //   lat: 1,                         //办公地点--纬度
                    //   lng: 1,                         //办公地点--经度
                    //   locationName: '',                         //办公地点--位置名称
                    //   validRange: '',                         //办公地点--范围限制
                    // }
                ],
                acrossCityLimits: 1,            //跨城限制--1:允许跨城、2:不允许跨城、3:不允许跨城，但部分临近城市可以跨城用车
                toFromCities: [                 //跨城限制(3:不允许跨城，但部分临近城市可以跨城用车--往返临近城市)--往返城市
                    {
                        city1Code: null,              //跨城限制(3:不允许跨城，但部分临近城市可以跨城用车--往返临近城市)--往返城市--城市1行政区编码
                        city2Code: null,              //跨城限制(3:不允许跨城，但部分临近城市可以跨城用车--往返临近城市)--往返城市--城市2行政区编码
                    }
                ],
                usageScenarios: [],            //使用场景 1：实时、2：预约 多个的话中间用逗号隔开
                carType: [],            //用车车型--1:经济、2:舒适、3:专车、4:商务、5:豪华
                carReason: false,   // 用车事由是否开启 0：否 1：是
                limitOfSingleAmount: 0,            //单笔金额限制--0:不限、1:限制
                singleAmount: 0,            //单笔金额限制(1:限制)--单笔金额限制
                dailyLimit: 0,            //每日限额--0:不限、1:限制
                dailyLimitAmount: '',            //每日限额(1:限制)--每日限额
                monthLimit: 0,            //每日限额--0:不限、1:限制
                monthLimitAmount: '',            //每日限额(1:限制)--每日限额
                dailyLimitTimes: 0,   //每日次数限制 0：不限 1：限制每日次数
                dailyTimes: '',  //每日次数(1:限制)--每日次数
                positionIds: [],            //职位ID
                userIds: [],            //用户ID
            };
            this.policyId = null;
            this.policyTypeChoice = null;
            this.policyTypeChoiceText = null;
        },
        /**
         *
         * @param {*} type 1:机票 2:火车票
         */
        // _getDialogShow(type) {
        //   this.dialogShow = true
        //   this.dialogType = type
        //   let _data = this.formData
        //   if (type === 1) {
        //     _data.refund = this.detail.airRefund
        //     _data.ticket = this.detail.airChange
        //   } else {
        //     _data.refund = this.detail.carRefund
        //     _data.ticket = this.detail.carChange
        //   }
        // },
        /**
         *  datas: 0:不限, 10:出差行程变化, 20:天气因素, 30:定错机票, 40:定错车次
         */
        _refund_hange_policy_get() {
            refund_hange_policy_get().then(res => {
                this.detail = res.datas;
            })
        }
    }
}
